<template>
  <div>
      <div class="container">
            <b-row class="rowas">
                <div class="col-12 col-sm-12 col-md-12 noPadding plusAncho title">
                    <p><img loading="lazy" alt="lupa-executive" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">Blogs <span>list</span></p>
                </div>
                <div class="col-12">
                    <b-alert class="alert" v-if="alert == 'created'" variant="success" show>
                        Successfully created. 
                        <span @click="addNew">Add one more?</span>
                    </b-alert>
                    <b-alert v-if="alert == 'edited'" variant="success" show>Successfully edited</b-alert>
                    <b-alert v-if="alert == 'deleted'" variant="success" show>Successfully deleted</b-alert>
                </div>
                <div class="col-12" id="addNewDiv">
                    <b-button variant="success" @click="addNew">Add new</b-button>
                </div>
                <div class="col-12" v-for="blog in blogs">
                    <blog @setDeleteAlert='setAlert' :blog='blog'></blog>
                </div>
            </b-row>
            <b-row class="clearfix" style="height: 50px;"></b-row>
      </div>
  </div>
</template>

<script>
import blog from './../../components/admin/Blog';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Blogs',
    metaInfo() {
        return {
            title: 'Blogs Administration'
        }
    },
    data() {
        return {
            alert: '',
        }
    },
    components: {
        blog
    },
    computed: mapState({
        blogs: state => state.blogs.all,
    }),
    methods: {
        openBlog() {
            console.log(this.blogs)
        },
        addNew() {
            this.$router.push({ name: 'newblog' });
        },
        setAlert() {
            this.alert = 'deleted';
        }
    },
    created() {
        this.$store.dispatch('blogs/getAllBlogs')
        this.alert = this.$route.params.alert;
    },
}
</script>

<style scoped>
    #addNewDiv {
        text-align: right;
        margin-bottom: 15px;
    }

    #addNewDiv button {
        width: 10rem;
    }

    .alert span {
        font-style: italic;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
    }

    .alert span:hover {
        color: #5c5d5c;
    }
</style>