<template>
    <div v-if="blog">
        <a @click="$router.push({name: 'blogpost', params: { id: blog.id}})" class="routerLink">
            <b-row class="wrapper">
                <div v-if="blog.image" class="col-md-3 imageCol" v-bind:style="{ backgroundImage: 'url(' + baseUrl + blog.image + ')' }">
                </div>
                <div class="col-md-9 textCol">
                    <h4>#{{ blog.id }} {{ blog.title }}</h4>
                    <div class="contentp" v-html="short_description(blog.content)">
                        <p ></p>
                    </div>
                    <div class="actions">
                        <font-awesome-icon v-on:click.stop="edit(blog)" :icon="['fas', 'edit']" class="socialIcon edit"/>
                        <font-awesome-icon v-on:click.stop="dangerModal = true;" :icon="['fas', 'trash']" class="socialIcon delete"/>
                    </div>
                    <div class="cardFooter">
                        <span v-if="blog.users">{{ blog.users.name }}</span>
                        <span class="date">{{ blog.created_at }}</span>
                    </div>
                </div>
            </b-row>
        </a>

        <hr id="hr">
        <b-modal 
            title="Are you sure you want to delete?" 
            header-bg-variant="danger" 
            header-text-variant="light"  
            centered  
            size="md" 
            v-model="dangerModal" 
            @ok="dangerModal = false; destroy(blog)"
        >
          You will not be able to recover it later!
        </b-modal>
    </div>
</template>

<script>
export default {
    props: ['blog'],
    data() {
        return {
            baseUrl: '',
            dangerModal: false,
        }
    },
    created() {
        process.env.NODE_ENV == 'development' ? 
            this.baseUrl =  'https://clubejecutivo.aicad.es/blogimages/' : 
            this.baseUrl = '/images/blogimages/'
    },
    methods: {
        edit(blog) {
            this.$router.push({ name: 'editblog', params: { id: blog.id } });
        },
        destroy(blog) {
            this.$store.dispatch('blogs/destroyBlog', blog.id ).then( () => {
                this.$emit('setDeleteAlert');
            });
        },
        short_description(content) {
            const regex = /<(\p)>(.*?)<\/\1>/;
            const matches = regex.exec(content);
            if(matches) {
                let l = matches[0].length;
                if(l < 150) {
                    let reg = '/^(.{' + l + '}[^\s]*).*/'
                    return matches[0].replace(reg, "$1").replace('</p>', '') + '...' + '</p>';
                } else{
                    return matches[0].replace(/^(.{150}[^\s]*).*/, "$1").replace('</p>', '') + '...' + '</p>';
                }
            } else {
                return '';
            }
        },
        openBlog(id) {
            this.$router.push({ name: 'blogpost', params: {'id': id} });
        }
    }
}
</script>

<style scoped>
    .imageCol {
        background-size: cover;
        background-position: center;
        height: 170px;
    }

    .wrapper {
        text-align: left;
        margin: 10px 0px;
        cursor: pointer;
        transition: 0.3s ease;
    }

    .wrapper:hover {
        transition: 0.3s ease;
        opacity: 0.9;
    }

    span.date {
        float: right;
    }

    .actions {
        text-align: right;
    }

    #hr {
        margin-top: 0px;
    }

    .socialIcon {
        margin-left: 10px;
        transition: 0.3s ease;
        cursor: pointer;
    }

    .socialIcon.edit:hover {
        color: #ffc107;
        transform: scale(1.1);
        transition: 0.3s ease;
    }

    .socialIcon.delete:hover {
        color: #dc3545;
        transform: scale(1.1);
        transition: 0.3s ease;
    }

    .textCol {
        display: flex;
        flex-direction: column;
    }

    .contentp {
        flex-grow: 1;
    }

    .cardFooter {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .routerLink {
        color: #2c3e50;
    }
</style>